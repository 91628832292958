export enum Domains {
	Default = 'default',
	Home = 'home',
	Help = 'help',
	PDP = 'pdp',
	PRODUCT_CARD = 'productCard',
	PLP = 'plp',
	Labels = 'labels',
	FeatureFlag = 'featureFlag',
	CountryConfiguration = 'countryConfiguration',
	Link = 'link',
	Payments = 'payments',
	Legal = 'legal',
	Product = 'product',
	ProductCustomization = 'productCustomization',
	Search = 'search',
	AnalyticsReader = 'analyticsReader',
	Checkout = 'checkout',
	Footer = 'footer',
	GiftVoucher = 'giftVoucher',
	PromoBanner = 'promoBanner',
	Menu = 'menu',
	MyAddresses = 'myAddresses',
	BackOffice = 'backOffice',
	MyPurchases = 'myPurchases',
	MyReturns = 'myReturns',
}
